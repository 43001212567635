.fullscreenmode {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.arrow {
  font-size: 72;
  color: #fff;
  cursor: pointer;
  user-select: none;
  border: 1px solid #000;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #000;
  bottom: 0; /* position at the bottom of the screen */
}

.leftArrow {
  position: absolute;
  left: 0;
}

.rightArrow {
  position: absolute;
  right: 0;
}

.imageTitle {
  position: absolute;
  bottom: 16px; /* increase bottom value to move title above close button */
  left: 50%;
  transform: translateX(-50%);
  font-size: 16px;
  color: #fff;
  text-align: center;
  width: 80%;
}

.closeButton {
  font-size: 16px;
  color: #fff;
  cursor: pointer;
  user-select: none;
  position: absolute;
  top: 16px; /* decrease top value to move close button below title */
  right: 50%;
  transform: translateX(50%);
  text-align: center;
  width: 64px;
  height: 32px;
  line-height: 32px;
  border-radius: 16px;
  background-color: rgba(0, 0, 0, 0.4);
}




.center {
  left: 50%;
  transform: translateX(-50%);
}



  
img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); /* center the image */
  max-height: 90%;
  max-width: 90%;
}

